<template>
  <b-overlay class="row" :show="loading">
    <div class="col-12"></div>
    <div class="col-12 col-lg-4 c g">
      <div class="card card-body">
        <button
          class="btn btn-relief-primary btn-block btn-lg"
          v-if="viewSave"
          @click="save()"
        >
          <i class="fa fa-save"></i>
          حفظ التعديلات
        </button>
        <button
          class="btn btn-relief-success btn-block"
          v-b-modal.modal-1
          @click="viewSave = true"
        >
          <i class="fa fa-plus"></i>
          إضافة مادة جديدة
        </button>
        <br />
        <div class="list-group">
          <button
            type="button"
            v-for="s in settings.list"
            :key="s.fullname"
            :id="'s' + s.id"
            class="list-group-item list-group-item-action"
            @click="
              subject = s;
              viewSave = true;
            "
          >
            {{ s.fullname }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-8 c g">
      <div class="card card-body" v-if="subject.fullname">
        <div class="form-group">
          <h5 for="">إسم المادة</h5>
          <input type="text" class="form-control" v-model="subject.fullname" />
        </div>
        <div class="form-group">
          <h5 for="">الإسم المختصر</h5>
          <input type="text" class="form-control" v-model="subject.shortname" />
        </div>
        <b-form-checkbox
          v-model="subject.has_near"
          :value="true"
          name="check-button"
          switch
          inline
        >
          ذات حصص متجاورة
        </b-form-checkbox>
        <hr />
        <div class="col-12 text-center">
          <h5>حجز المواد آلياً</h5>
        </div>
        <div class="col-12 table-responsive custom-table">
          <table class="table table-hover table-bordered">
            <thead>
              <th>اليوم</th>
              <th>الأولى</th>
              <th>الثانية</th>
              <th>الثالثة</th>
              <th>الرابعة</th>
              <th>الخامسة</th>
              <th>السادسة</th>
              <th>السابعة</th>
              <th>الثامنة</th>
            </thead>
            <tbody>
              <tr v-for="day in days" :key="day.code">
                <td>{{ day.name }}</td>
                <td v-for="index in 8" :key="index">
                  <b-form-checkbox
                    v-model="subject.table[day.code + '|' + index]"
                    :value="index"
                    name="check-button"
                    inline
                  >
                    حجز
                  </b-form-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <div class="col-12 text-right">
          <button
            class="btn btn-sm btn-link text-danger"
            @click="deleteSubject(subject.id)"
          >
            <i class="fa fa-trash"></i>
            حذف
          </button>
        </div>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal id="modal-1" title="إضافة مادة جديدة" ok-only ok-title="اغلاق">
      <div class="form-group">
        <h5 for="">إسم المادة</h5>
        <input type="text" class="form-control" v-model="addSubject.fullname" />
      </div>
      <div class="form-group">
        <h5 for="">الإسم المختصر</h5>
        <input
          type="text"
          class="form-control"
          v-model="addSubject.shortname"
        />
      </div>
      <b-form-checkbox
        v-model="addSubject.has_near"
        value="true"
        name="check-button"
        switch
        inline
      >
        ذات حصص متجاورة
      </b-form-checkbox>
      <div class="col-12 text-center g">
        <button class="btn btn-relief-success" @click="add()">
          إضافة المادة
          <i class="fa fa-arrow-left"></i>
        </button>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BFormGroup,
  BFormRadioGroup,
  BModal,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BFormGroup,
    BFormRadioGroup,
    BModal,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      subject: {},
      addSubject: {},
      loading: true,
      viewSave: false,
      settings: {},
      user: user,
      days: [
        {
          code: "1",
          name: "الأحد",
          list: {},
        },
        {
          code: "2",
          name: "الاثنين",
          list: {},
        },
        {
          code: "3",
          name: "الثلاثاء",
          list: {},
        },
        {
          code: "4",
          name: "الاربعاء",
          list: {},
        },
        {
          code: "5",
          name: "الخميس",
          list: {},
        },
      ],
    };
  },
  created() {
    var g = this;
    $.post(api + "/table/settings", {
      jwt: user.jwt,
      type: "subjects",
    })
      .then(function (r) {
        r = JSON.parse(r);
        g.settings = r;
        g.loading = false;
        if (window.location.href.includes("#")) {
          setTimeout(() => {
            $("#s" + window.location.href.split("#")[1]).click();
          }, 100);
        }
      })
      .catch(function () {
        swal.fire(
          "حدث خطأ اثناء الاتصال بالخادم",
          "برجاء المحاولة مرة أخرى",
          "warning"
        );
        g.loading = false;
      });
  },
  beforeDestroy() {
    if (this.viewSave && confirm("هل تريد حفظ الإعدادات قبل المغادرة؟")) {
      this.save();
    }
  },
  methods: {
    deleteSubject(id) {
      if (confirm("متأكد من حذف المادة؟")) {
        var arr = [];
        this.settings.list.forEach((element) => {
          if (element.id != id) {
            arr.push(element);
          }
        });
        this.settings = {
          list: arr,
        };
        this.subject = {};
        this.save(true);
      }
    },
    add() {
      if (!this.addSubject.fullname || !this.addSubject.shortname) {
        swal.fire(
          "هناك حقول مطلوبة",
          "برجاء التأكد من ملئ كامل الحقول المطلوبة",
          "warning"
        );
      } else {
        this.settings.list = this.settings.list ? this.settings.list : [];
        var id = Math.random().toString().split(".")[1];
        this.settings.list.push({
          fullname: this.addSubject.fullname,
          shortname: this.addSubject.shortname,
          has_near: this.addSubject.has_near,
          table: { "1|1": "false" },
          id: id,
        });
        this.subject = {
          fullname: this.addSubject.fullname,
          shortname: this.addSubject.shortname,
          has_near: this.addSubject.has_near,
          table: { "1|1": "false" },
          id: id,
        };
        this.addSubject = {};
        $("#modal-1___BV_modal_footer_ .btn").click();
      }
    },
    save(l = false) {
      var g = this;
      g.loading = true;
      if (this.subject.fullname && this.settings.list.length) {
        var index = 0;
        this.settings.list.forEach(function (a) {
          if (a.id == g.subject.id) {
            g.settings.list[index] = g.subject;
          }
          index++;
        });
      }
      $.post(api + "/table/settings/save", {
        jwt: user.jwt,
        type: "subjects",
        value: JSON.stringify(this.settings),
      })
        .then(function (r) {
          if (!l) {
            g.save(true);
          } else {
            g.loading = false;
            swal.fire("تم بنجاح", "", "success");
          }
        })
        .catch(function () {
          swal.fire(
            "حدث خطأ اثناء الاتصال بالخادم",
            "برجاء المحاولة مرة أخرى",
            "warning"
          );
          g.loading = false;
        });
    },
  },
};
</script>